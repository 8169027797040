<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>User Mode Aggregate Logs</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <!-- <v-text-field v-model="search" label="ModeId" single-line v-on:keyup.enter="initialize" hide-details></v-text-field> -->
      <v-autocomplete
          v-model="modeId"
          label="Mode"
          :items="gameType"
          item-value="key"
          item-text="value"
          @change="initialize"
          placeholder="Select Mode"
        ></v-autocomplete>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates"
        transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="dates" label="Picker in menu" prepend-icon="mdi-calendar" readonly single-line
            hide-details v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="dates" locale="tr-TR" range no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="initialize">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox v-model="pageItemSelect" :items="pageItems" item-value="id" item-text="value" @change="initialize"
        hide-details label="Page"></v-combobox>
      <v-btn color="primary" dark @click="initialize" class="mb-2 ma-2">Search</v-btn>
    </v-toolbar>
    <v-data-table :headers="userModeAggregateLogsDataTable" :items="$store.state.userModeAggregateLogs.all.data"
      :items-per-page="pageItemSelect.id" hide-default-footer :loading="loading" :options.sync="options"
      loading-text="Loading... Please wait" class="elevation-1">
      
      <template v-slot:item.u="{ item }">
        <div>{{ item.u }}</div>
      </template>
      <template v-slot:item.un="{ item }">
        <div>{{ item.un }}</div>
      </template>
      <template v-slot:item.l="{ item }">
        <div>{{ item.l }}</div>
      </template>
      <template v-slot:item.s="{ item }">
        <div>{{ item.s | formatMoney }}</div>
      </template>
      <template v-slot:item.b="{ item }">
        <div>{{ item.b | formatMoney }}</div>
      </template>
      <template v-slot:item.t="{ item }">
        <div>{{ item.t | formatMoney }}</div>
      </template>
      <template v-slot:item.fs="{ item }">
        <div>{{ item.fs | formatMoney }}</div>
      </template>
      <template v-slot:item.diff="{ item }">
        <div v-bind:style="{
          color: item.t - item.b > 0 ? 'lime' : '#FF6D6D',
        }">
          {{ (item.t - item.b) | formatMoney }}
        </div>
      </template>
      <template v-slot:item.winProfit="{ item }">
        <div v-bind:style="{
          color: item.t - item.b > 0 ? 'lime' : '#FF6D6D',
        }">
          {{ Math.round(((item.t - item.b) / item.b) * 100) | formatMoney }}%
        </div>
      </template>
      <template v-slot:item.winRate="{ item }">
        <div v-bind:style="{
          color: item.t - item.b > 0 ? 'lime' : '#FF6D6D',
        }">
          {{ Math.round((item.t / item.b) * 100) / 100 }}X
        </div>
      </template>
      <template v-slot:item.f="{ item }">
        <div>{{ item.f | formatMoney }}</div>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination v-model="currentPage" @input="pageClick" :total-visible="20"
        :length="$store.state.userModeAggregateLogs.all.totalPage"></v-pagination>
    </div>
    <!-- <v-row justify="center">
      <v-dialog v-model="dialog" max-width="1600px">
        <v-card>
          <v-card-title>
            <span class="headline">Mode Detail Logs</span>
          </v-card-title>
          <v-data-table :headers="modeLogDetailsTable" :items="$store.state.modeLogDetails.all" hide-default-footer
            :loading="loading" loading-text="Loading... Please wait" class="elevation-1">
            <template v-slot:item.c="{ item }">
              <div>{{ Math.round((item.c * 100) / 100) | formatMoney }}</div>
            </template>
            <template v-slot:item.s="{ item }">
              <div>
                {{
                  item.s == 0
                  ? "None"
                  : item.s == 1
                    ? "FreeSpins"
                    : item.s == 2
                      ? "Wild"
                      : item.s == 4
                        ? "Medusa"
                        : item.s == 8
                          ? "Lion"
                          : item.s == 16
                            ? "Bull"
                            : item.s == 32
                              ? "Eagle"
                              : item.s == 64
                                ? "Delta"
                                : item.s == 128
                                  ? "K"
                                  : item.s == 256
                                    ? "Q"
                                    : item.s == 512
                                      ? "J"
                                      : item.s == 1024
                                        ? "Candy"
                                        : item.s == 2048
                                          ? "Ten"
                                          : item.s == 4096
                                            ? "Nine"
                                            : item.s == 8192
                                              ? "Minor"
                                              : item.s == 16384
                                                ? "Major"
                                                : item.s == 32768
                                                  ? "Grand"
                                                  : item.s == 65536
                                                    ? "Bet"
                                                    : item.s == 131072
                                                      ? "Scatter"
                                                      : item.s == 262144
                                                        ? "Jackpot"
                                                        : item.s == 524288
                                                          ? "Multiplier"
                                                          : "Check Symbol"
                }}
              </div>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row> -->
  </div>
</template>
<script>
import "@/assets/css/site.css";
import gameType from "@/lib/gameType";

export default {
  components: {},
  name: "UserModeAggregateLogsListCard",
  props: {
    search: {
      type: Number,
    },
  },
  data() {
    return {
      dates: [
        new Date(Date.now())
          .addDays(-30)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now()).toISOString().substr(0, 10),
      ],
      menu: false,
      dialog: false,
      detailData: [],
      pageCount: 0,
      typeSelect: { key: -999, value: "All" },
      pageItemSelect: { id: 25, value: "25" },
      loading: false,
      currentPage: 1,
      gameType: gameType,
      modeId:1,
      options: {},
      pagination: {
        sortBy: "name",
      },
      pageItems: [
        { id: 25, value: "25" },
        { id: 50, value: "50" },
        { id: 100, value: "100" },
        { id: 250, value: "250" },
        { id: 500, value: "500" },
        { id: 1000, value: "1000" },
        { id: 2000, value: "2000" },
        { id: 5000, value: "5000" },
        { id: 10000, value: "10000" },
      ],
      userModeAggregateLogsDataTable: [
        { text: "User Id", value: "u" },
        { text: "User Name", value: "un" },
        { text: "Level", value: "l" },
        { text: "Spin Count", value: "s" },
        { text: "Sum - Bet Level", value: "b" },
        { text: "Sum - Total Won Coins", value: "t" },
        { text: "Sum - Free Spin Won Coins", value: "fs" },
        { text: "Sum - Bet Win Diff", value: "diff" },
        { text: "Win Rate", value: "winRate" },
        { text: "Profit %", value: "winProfit" },
        { text: "Sum - Free Spin", value: "f" },
        // { text: "Spin Result Details", value: "action", sortable: false },
      ],
      // modeLogDetailsTable: [
      //   { text: "Winning Symbol", value: "s" },
      //   { text: "Total Won Coins", value: "c" },
      // ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.options.sortBy.length > 0) this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },

    async initialize() {
      this.$refs.menu.save(this.dates);
      this.loading = true;
      await this.$store.dispatch("userModeAggregateLogs/getAll", {
        modeId:this.modeId,//this.search!==Number ||this.search===0?1:this.search,
        startDate:
          new Date(
            new Date(this.dates[0]).getTime() +
            new Date().getTimezoneOffset() * 60000
          ) / 1000,
        endDate: new Date(this.dates[1]).addDays(1).getTime() / 1000,
        size: this.pageItemSelect.id,
        page: this.currentPage - 1,
      });
      // await this.$store.dispatch("modeAggregateLogs/getAllWithQuery", {
      //   startDate:
      //     new Date(
      //       new Date(this.dates[0]).getTime() +
      //         new Date().getTimezoneOffset() * 60000
      //     ) / 1000,
      //   endDate: new Date(this.dates[1]).addDays(1).getTime() / 1000,
      // });
      // eslint-disable-next-line no-console
      console.log(this.$store.state.userModeAggregateLogs.all.data);
      this.loading = false;
    },
    async detail(item) {
      this.dialog = true;
      await this.$store.dispatch("modeLogDetails/getAllFromBackend", {
        modeId: item.m,
        startDate:
          new Date(
            new Date(this.dates[0]).getTime() +
            new Date().getTimezoneOffset() * 60000
          ) / 1000,
        endDate: new Date(this.dates[1]).addDays(1).getTime() / 1000,
        size: this.pageItemSelect.id,
        page: this.currentPage - 1,
      });
      this.detailData = this.$store.state.modeLogDetails.all;
      // eslint-disable-next-line no-console
      console.log(this.detailData);
      const bySymbol = this.groupBy(this.detailData, (x) => x.s);
      // eslint-disable-next-line no-console
      console.log(bySymbol);
    },
  },

  async mounted() {
    await this.initialize();
  },
};
</script>

<style>
.card-p {
  width: 142px;
  height: 155px;
  background-size: 142px 155px;
  background-repeat: no-repeat;
  border-radius: 9.5px;
  margin-right: 12px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.lime-card {
  background-image: url(https://bundle.slotbase.net/assets/img/green.png);
}

.green-card {
  background-image: url(https://bundle.slotbase.net/assets/img/green.png);
}

.red-card {
  background-image: url(https://bundle.slotbase.net/assets/img/red.png);
}

.orange-card {
  background-image: url(https://bundle.slotbase.net/assets/img/orange.png);
}

.v-tooltip__content {
  opacity: 1 !important;
}

.jv-container {
  background: #0000005c !important;
}

.jsoneditor-field {
  color: #b7b3c3 !important;
}

a.jsoneditor-value.jsoneditor-url,
div.jsoneditor-value.jsoneditor-url {
  color: #00cfff !important;
}

div.jsoneditor-value.jsoneditor-array,
div.jsoneditor-value.jsoneditor-object {
  color: #b7abab !important;
}

.ace-jsoneditor .ace_variable,
.ace-jsoneditor .ace_gutter-cell {
  color: #fff !important;
}

div.jsoneditor-value.jsoneditor-string {
  color: #00cfff;
}

.ace-jsoneditor .ace_scroller,
.ace-jsoneditor .ace_gutter {
  background: #000 !important;
}
</style>
