<template>
  <user-mode-aggregate-logs-card />
</template>
<script>
import UserModeAggregateLogsCard from "./components/UserModeAggregateLogsCard.vue";
export default {
  components: {
    UserModeAggregateLogsCard,
  },
};
</script>
